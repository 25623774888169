import * as globalUtils from "../src/utils/helper"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { getConfig } from "@/constants/config"
import { getUserPersona } from "@/utils/helper"
import { getCart, setCart, setCartCount } from "@/store/features/cartSlice"
import { getQuantityCount } from "@/js/helperV2"
import { apim } from "@/constants/api"
import { store } from "@/store"
import { getShippingAllTotal } from "@/utils/"
import {
  addCompareProducts,
  clearProduct,
  updateCompareStatus,
} from "@/store/features/compareSlice"
import { CATALOG_PROJECTION_ENDPOINT } from "@/constants/routes"

export const randomWholeNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const getPresetUrl = (
  width = 0,
  swatchUrl = "",
  image = "",
  presetConfigs = {},
  scene7AccountName = ""
) => {
  const { Desktop, MobileLandscape, MobilePortrait, PotraitCategory } =
    presetConfigs
  const assetId = image.split("/").pop()
  let scene7Url = ""
  if (swatchUrl.toString().includes("PAWEB")) {
    scene7Url = swatchUrl.replace("PAWEB", scene7AccountName)
  } else {
    scene7Url = swatchUrl
  }

  let preset = ""
  if (width < 1920 && width >= 1440) {
    preset = Desktop
  } else if (width < 1440 && width >= 1024) {
    preset = Desktop
  } else if (width < 1024 && width >= 768) {
    preset = MobileLandscape
  } else if (width < 768) {
    preset = MobilePortrait
  } else {
    preset = Desktop
  }
  return `${scene7Url}${PotraitCategory}?$product_src=is{${scene7AccountName}/${assetId}}&${preset}`
}

export const initOverlayScrollbar = ref => {
  let instance = null
  if (window.OverlayScrollbarsGlobal) {
    instance = window.OverlayScrollbarsGlobal.OverlayScrollbars(ref?.current, {
      paddingAbsolute: true,
      showNativeOverlaidScrollbars: false,
      update: {
        elementEvents: [["img", "load"]],
        debounce: [0, 33],
        attributes: null,
        ignoreMutation: null,
      },
      overflow: {
        x: "scroll",
        y: "scroll",
      },
      scrollbars: {
        theme: "os-theme-dark",
        visibility: "auto",
        autoHide: "never",
        autoHideDelay: 1300,
        dragScroll: true,
        clickScroll: true,
        pointers: ["mouse", "touch", "pen"],
      },
    })
  }

  return instance
}

export const addScrollCss = (ref, selectorClass, className) => {
  if (ref?.current?.querySelectorAll(selectorClass)) {
    ref?.current?.classList.add(className)
  } else {
    ref?.current?.classList.remove(className)
  }
}
let isValueInBoolean = (booleanValues, filtersInfo) =>
  booleanValues.filter(value =>
    filtersInfo
      .map(fi => fi.toLowerCase().replaceAll(" ", ""))
      .includes(value.toLowerCase())
  )
export const findStoreBuildQueryFromUrl = (filtersInfo, filterCheck = []) => {
  let result = filtersInfo
    ?.map(fi => {
      const boolValue = isValueInBoolean(filterCheck, fi?.display)
      if (boolValue.length) {
        return `(${boolValue.map(bv => `${bv} eq true`).join(" And ")})`
      }
      return `(${fi?.value
        ?.map(fq => `${fi.facet?.split(" ").join("")} eq '${fq}'`)
        .join(" Or ")})`
    })
    .join(" And ")

  if (result) {
    result = `(${result})` + " And "
    return result
  }
  return ""
}

export const findStoreBuildQuery = tabDetails => {
  return tabDetails.map(tabDetail => {
    const combineTabQueries = tabDetail
      .map(tab => `${tab?.name?.replace(" ", "")} eq '${tab?.bingValue}'`)
      .join(" Or ")
    return `(${combineTabQueries})`
  })
}
export const removeCharactersAfterNumber = entityId => {
  let num = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
  let lastIndex = entityId.length - 1
  while (lastIndex > 0) {
    if (num.includes(entityId[lastIndex])) {
      break
    }
    lastIndex--
  }
  let result = entityId.slice(0, lastIndex + 1)
  return result
}

export const getProductsBySku = async skus => {
  let query = ""
  skus.forEach((sku, i) => {
    query += `"${sku}"`
    if (i + 1 !== skus.length) query += ", "
  })
  const res = await apim.get(
    `${CATALOG_PROJECTION_ENDPOINT}?where=masterVariant(sku in (${query})) or variants(sku in (${query}))&brand=annsacks`
  )
  if (res && res.data && res.data.results) return res.data.results
  return []
}

export const addToCompareProduct = (sku, isBackOrder) => {
  store.dispatch(updateCompareStatus(true))
  getProductsBySku([sku])
    .then(async resp => {
      resp.forEach(vsku => {
        store.dispatch(
          addCompareProducts({
            sku: vsku,
            backorder: isBackOrder,
            compareSku: sku,
          })
        )
      })
    })
    .catch(error => {
      store.dispatch(updateCompareStatus(false))
    })
}

export const removeFromCompareProduct = sku => {
  store.dispatch(clearProduct(sku))
}

/**
 * This function returns an object which will have customer group value which is based on user persona
 * and fallback customer group value which is always guest(GST).
 * @returns {object}
 */
export const getCustomerGroup = async () => {
  const CONFIG = await getConfig()
  const priceUpdate = _get(CONFIG, "general.priceUpdate", "")
  const persona = getUserPersona()
  const personaObj = {}
  const pairs = priceUpdate ? priceUpdate.split(";") : []
  pairs.length &&
    pairs.forEach(pair => {
      const [abbreviation, expansion] = pair.split(":")
      personaObj[abbreviation] = expansion
    })
  const customerGroupObject = {
    customerGroup: _get(personaObj, persona, ""),
    fallbackCustomerGroup: _get(personaObj, "GST", ""),
  }
  return customerGroupObject
}

/**
 * This function returns true when source includes customerGroup and vice versa
 * @param {string} source - key attribute from CT api response
 * @param {string} customerGroup - priceUpdate attribute from AEM CA config
 * @returns {boolean}
 */
export const isCustomerGroupPresent = (source = "", customerGroup = "") => {
  const substrings = customerGroup
    .toLowerCase()
    .split(",")
    .map(sub => sub.trim())
  const isMatch = substrings[0].length
    ? substrings.some(sub => source.toLowerCase().includes(sub))
    : false
  return isMatch
}
/**
 * This function will return an object which will have the user persona and fallback price which can be embed or standalone price
 * for the given data of annsacks products
 * @param {object} data - Master variant object from CT api response
 * @param {string} key - price attribute inside master variant object For ex: standalone, prices.
 * @param {string} customerGroup - customer group value from getCustomerGroup function
 * @param {string} fallbackCustomerGroup - fallback customer group value from getCustomerGroup function
 * @returns {object}
 */
export const getAsPrice = (
  allVariants = [],
  allPriceKeys = [],
  customerGroup = "",
  fallbackCustomerGroup = "",
  currencyCode = ""
) => {
  const persona = getUserPersona()

  const allSkuData = {}
  const getPriceValue = (obj = {}, keyVal = "") => {
    return Number((Number(_get(obj, keyVal, 0)) / 100).toFixed(2)) ?? ""
  }
  allVariants.forEach((data = {}) => {
    if (!data?.sku?.includes("IMGV")) {
      allPriceKeys.forEach((key = "") => {
        let obj, fallbackObj, anyPriceObj
        if (key === "prices") {
          obj = data?.[key]?.find(
            item =>
              item.value.currencyCode === currencyCode &&
              item.customerGroup?.obj?.key === persona
          )
          fallbackObj = data?.[key]?.find(
            item =>
              item.value.currencyCode === currencyCode &&
              item.customerGroup?.obj?.key === "GST"
          )
          anyPriceObj = data?.[key]?.find(
            item =>
              item?.value?.currencyCode === currencyCode && !item?.customerGroup
          )
          const ctPricelistUserPersona = getPriceValue(obj, "value.centAmount")
          const ctPricelistUserPersona_d = getPriceValue(
            obj,
            "discounted.value.centAmount"
          )
          const ctPricelistGstPersona = getPriceValue(
            fallbackObj,
            "value.centAmount"
          )
          const ctPricelistGstPersona_d = getPriceValue(
            fallbackObj,
            "discounted.value.centAmount"
          )
          const ctAnyPrice = getPriceValue(anyPriceObj, "value.centAmount")
          const ctAnyPrice_d = getPriceValue(
            anyPriceObj,
            "discounted.value.centAmount"
          )
          const priceObject = {
            ...allSkuData[data.sku],
            ctPricelistUserPersona,
            ctPricelistUserPersona_d,
            ctPricelistGstPersona,
            ctPricelistGstPersona_d,
            ctAnyPrice,
            ctAnyPrice_d,
          }
          allSkuData[data.sku] = priceObject
        } else {
          obj = data?.[key]?.find(
            item =>
              item.value.currencyCode === currencyCode &&
              isCustomerGroupPresent(item.key, customerGroup)
          )
          fallbackObj = data?.[key]?.find(
            item =>
              item.value.currencyCode === currencyCode &&
              isCustomerGroupPresent(item.key, fallbackCustomerGroup)
          )
          const ctStandaloneUserPersona = getPriceValue(obj, "value.centAmount")
          const ctStandaloneUserPersona_d = getPriceValue(
            obj,
            "discounted.value.centAmount"
          )
          const ctStandaloneGstPersona = getPriceValue(
            fallbackObj,
            "value.centAmount"
          )
          const ctStandaloneGstPersona_d = getPriceValue(
            fallbackObj,
            "discounted.value.centAmount"
          )
          const priceObject = {
            ...allSkuData[data.sku],
            ctStandaloneUserPersona,
            ctStandaloneUserPersona_d,
            ctStandaloneGstPersona,
            ctStandaloneGstPersona_d,
          }
          allSkuData[data.sku] = priceObject
        }
      })
    }
  })
  return allSkuData
}

/**
 * Based on the priority this function will return the price or the discount price which can be either embed or standalone price from LW or CT api for the annsacks products.
 * @param {Object} obj - price object which comprise all the price data
 * @param {boolean} isDiscount - boolean attribute which is used to return discounted price
 * @param {string} lwAnyPrice - ANY price of LW embed price
 * @returns {number|string}
 */
export const getAsFinalPrice = (obj, isDiscount, lwAnyPrice = "") => {
  const {
    ctStandaloneUserPersona = "",
    ctStandaloneUserPersona_d = "",
    ctStandaloneGstPersona = "",
    ctStandaloneGstPersona_d = "",
    ctPricelistUserPersona = "",
    ctPricelistUserPersona_d = "",
    ctPricelistGstPersona = "",
    ctPricelistGstPersona_d = "",
    ctAnyPrice = "",
    ctAnyPrice_d = "",
  } = obj ?? {}
  if (ctStandaloneUserPersona) {
    return isDiscount ? ctStandaloneUserPersona_d : ctStandaloneUserPersona
  } else if (ctStandaloneGstPersona) {
    return isDiscount ? ctStandaloneGstPersona_d : ctStandaloneGstPersona
  } else if (ctPricelistUserPersona) {
    return isDiscount ? ctPricelistUserPersona_d : ctPricelistUserPersona
  } else if (ctPricelistGstPersona) {
    return isDiscount ? ctPricelistGstPersona_d : ctPricelistGstPersona
  } else if (ctAnyPrice) {
    return isDiscount ? ctAnyPrice_d : ctAnyPrice
  } else {
    return lwAnyPrice
  }
}
export const loadSmartcrop = cb => {
  const scriptExist = document.getElementById("smartcrop-react")
  if (!scriptExist && !window.s7responsiveImage) {
    let res = getConfig().apiEndpoints
    let script = document.createElement("script")
    script.src = _get(res, "smartCropScript", "")
    script.id = "smartcrop-react"
    script.onload = function onload() {
      cb(true)
    }
    document.body.appendChild(script)
  }
}

export const cleanHtmlTextForDatalayer = (text = "") =>
  globalUtils?.cleanText(text)?.replace(/[.\n]/g, "").toLowerCase().trim()

export const getPdpFLs = (persona = "") => {
  return [
    "ProductDescriptionSmall_s",
    "variantList.sku_s",
    "variantList.sku_ss",
    "Product_Category",
    "ProductBrandNameDisplay_s",
    "ProductLocalCategory_s",
    "Super_Sku",
    "masterSKU_s",
    "ProductDetailImageURL_s",
    "Color.Name",
    "colorFinishFamilyList_ss",
    "Color.SKU.Details_s",
    "Color.SKU.Details_ss",
    "SKU.Details_ss",
    "ProductYoutubeID_s",
    "ProductNarrativeDescription_s",
    "ProductExclusive_s",
    "ProductPageTitle_s",
    "ProductBrandName_s",
    "PriceList.*.displayProductFinalPrice_s",
    "ProductDescriptionProductShort_s",
    "SKUSamples_ss",
    "Color.Family.Count.Details",
    "ProductDescriptionDetail_s",
    "ProductWebFeatures_s",
    "ProductWebFeatures_ss",
    "ProductWebTechnology_s",
    "ProductWebTechnology_ss",
    "ProductWebTechnology_s",
    "ProductResource.TearSheetPDF.resourceFullWebURL_ss",
    "ProductResource.TearSheetPDF.resourceType_s",
    "ProductDescriptionGeneric_s",
    "sampleSKUList_s",
    "sampleSKUList_ss",
    "productImages.labelWithUrl_s",
    "productImages.labelWithUrl_ss",
    "priceList.*.saleOffer_s",
    "productName_s",
    "CustomerFacingBrand_s",
    "productRegionAllVariantDiscontinued_b",
    "ProductOverallLengthInches_s",
    "ProductOverallWidthInches_s",
    "ProductOverallHeightInches_s",
    "ProductIsRetail",
    "ProductFreightPolicyType_s",
    "ProductRequiredAccessoryGroup_s",
    "ProductRequiredAccessoryGroup_s",
    "priceList.ANY.price_d",
    "ProductDesigner_s",
    "Color.Finish.Type.Details_ss",
    "colorFinishTypeList_ss",
    "ProductWebMaterial_ss",
    "ProductWebInstallation_ss",
    "RegionSoldOnline",
    "ProductProductRecommendedAccessories_ss",
    "category1_s",
    "ProductBrandNameDisplay_t",

    "ProductResource.DWGFrontView.resourceFullWebURL_ss",
    "ProductResource.DWGPlanView.resourceFullWebURL_ss",
    "ProductResource.DWGSideView.resourceFullWebURL_ss",

    "ProductResource.3D3DS.resourceFullWebURL_ss",
    "ProductResource.3DDWGSymbol.resourceFullWebURL_ss",
    "ProductResource.3DDXFSymbol.resourceFullWebURL_ss",
    "ProductResource.3DOBJ.resourceFullWebURL_ss",
    "ProductResource.3DRevit.resourceFullWebURL_ss",
    "ProductResource.3DSketchup.resourceFullWebURL_ss",

    "ProductResource.DXFFrontView.resourceFullWebURL_ss",
    "ProductResource.DXFPlanView.resourceFullWebURL_ss",
    "ProductResource.DXFSideView.resourceFullWebURL_ss",

    "ProductResource.SpecPDFFileName.resourceFullWebURL_ss",
    "ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss",
    `ProductPriceSpiderIncluded_s`,
    `isCollapsedPDP_b`,
    "pdp_url_s",
  ]
}

/**
 *
 * @param {String} swatchUrl
 * @param {String} assetId
 * @param {Object} presetConfigs
 * @returns
 */
export const getPresetThumbnailUrl = (swatchUrl, assetId, presetConfigs) => {
  const { Thumbnail, LandscapeCategory } = presetConfigs
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${Thumbnail}`
}

/**
 *
 * @param {String} sUrl
 * @param {Number} iWidth
 * @param {Object} presetConfigs
 * @param {Boolean} isReel
 * @returns // image url
 */
export const appendPresetToUrl = (
  swatchUrl,
  assetId,
  width,
  presetConfigs = {},
  isReel,
  assetAccountName
) => {
  const {
    Desktop,
    MobileLandscape = "",
    MobilePortrait = "",
    LandscapeCategory,
  } = presetConfigs

  let preset = ""
  if (width <= 768) {
    if (width <= 425) {
      if (MobilePortrait) {
        preset = MobilePortrait
      }
    } else {
      if (MobileLandscape) {
        preset = MobileLandscape
      }
    }
  } else {
    preset = isReel ? Desktop : MobileLandscape
  }
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${preset}`
}

export function getAsPDPThumbnail(
  swatchUrl,
  presetConfig,
  imageId,
  assetAccountName
) {
  const { Thumbnail, LandscapeCategory } = presetConfig
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${imageId}}&?${Thumbnail}`
}

export function getPDPPresetUrl(
  swatchUrl,
  presetConfig,
  imageUrl,
  width,
  template = null,
  assetAccountName
) {
  const {
    Desktop,
    LandscapeCategory,
    MobileLandscape,
    MobilePortrait,
    PotraitCategory,
  } = presetConfig
  let preset = Desktop
  let category
  if (template) {
    category = presetConfig[template]
  } else {
    if (width <= 768) {
      if (width <= 425) {
        if (MobilePortrait) {
          preset = MobilePortrait
          category = PotraitCategory
        }
      } else {
        if (MobileLandscape) {
          preset = MobileLandscape
        }
      }
    }
  }

  const urlParts = imageUrl && imageUrl?.split("/")
  const imageId = urlParts && urlParts[urlParts?.length - 1]
  return `${swatchUrl}${category}?$product_src=is{${assetAccountName}/${imageId}}&?${preset}`
}

/**
 * Based on the user persona this function will return the Sale and Discount filter facet with all other filters.
 * @param {Object} obj - filter object which comprises all the filter list
 * @returns {object} 
 */
export const getAsUniqueFilter = (obj) => {
  const persona = getUserPersona()
  Object.keys(obj).forEach(key => {
      if(key.includes('saleAvailable_s') && key !== `priceList.${persona}.saleAvailable_s`) {
          delete obj[key]
      }
      if(key.includes('discountOff_s') && key !== `priceList.${persona}.discountOff_s`) {
        delete obj[key]
    }
  })
  return obj
}

// Re-export globalUtils functions from the helper module
export * from "../src/utils/helper"
