import { COMPONENT_TYPES } from "@/constants/index"
import * as globalUtils from "../src/utils"

export const isLogoHeader = data => {
  const types = []

  data[":itemsOrder"]?.forEach(key => {
    const type = data[":items"][key][":type"]
    if (type) {
      types.push(type)
    }
  })

  return (
    types.find(a => a.includes(COMPONENT_TYPES.IMAGE)) &&
    types.find(a => a.includes(COMPONENT_TYPES.BUTTON)) &&
    types.find(a => a.includes(COMPONENT_TYPES.BUTTON))
  )
}
export const isSlick = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    !item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}
export const getBrandSwitcher = (headerData = {}) => {
  const headPropsItems = headerData[":items"]
  const headerPropsRoot = headPropsItems?.root
  const headerRootItems = headerPropsRoot[":items"]
  const headerPropsContainer = headerRootItems?.container
  const headerContainerItems = headerPropsContainer[":items"]
  const headerUtilityWidgets = headerContainerItems?.brand_switcher
  return headerUtilityWidgets?.items ?? ""
}
export const isPromoBanner = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}
export const isBrandNav = data => {
  const types = []

  data[":itemsOrder"]?.forEach(key => {
    const type = data[":items"][key][":type"]
    if (type) {
      types.push(type)
    }
  })

  return (
    (types.find(a => a.includes(COMPONENT_TYPES.BUTTON)) &&
      types.find(a => a.includes(COMPONENT_TYPES.UTILITY_WIDGET))) ||
    types.find(a => a.includes(COMPONENT_TYPES.TABS))
  )
}
export const getPresetUrl = (
  width = 0,
  rowCols = 0,
  swatchUrl = "",
  assetId = "",
  presetConfigs = {},
  isForInstallationService = false
) => {
  const {
    Desktop,
    MobilePortrait,
    MobilePortrait1,
    MobilePortrait2,
    MobileLandscape,
    MobileLandscape1,
    MobileLandscape2,
    PotraitCategory,
  } = presetConfigs
  let preset = ""
  const scene7AccountName = swatchUrl?.split("/")[5] ?? "PAWEB"
  const mobileBasedCheckPoint = isForInstallationService ? 1023 : 768
  if (width <= mobileBasedCheckPoint) {
    if (width <= 425) {
      if (rowCols) {
        preset = MobilePortrait2 || MobilePortrait
        if (rowCols === 1) preset = MobilePortrait1 || MobilePortrait
      } else if (MobilePortrait) {
        preset = MobilePortrait
      } else preset = MobilePortrait2
    } else {
      if (rowCols) {
        if (rowCols === 1) preset = MobileLandscape1 || MobileLandscape
        else preset = MobileLandscape2 || MobileLandscape
      } else if (MobileLandscape) {
        preset = MobileLandscape
      } else preset = MobileLandscape1
    }
  } else preset = Desktop

  if (isForInstallationService) {
    return `${swatchUrl}${assetId}?${preset}`
  }

  return `${swatchUrl}${PotraitCategory}?$product_src=is{${scene7AccountName}/${assetId}}&${preset}`
}
export const findStoreUtilNavselectQuery =
  "EntityID,BPnumber,LocationName,AddressLine,AddressLine2,StateLong,AdminDistrict,PostalCode,Latitude,__Distance,Longitude,Phone,Locality"

export const isTeaserV2 = item =>
  item[":type"]?.includes(COMPONENT_TYPES.TEASER_V2)

export const isBlogCarousel = item =>
  item[":type"]?.includes(COMPONENT_TYPES.BLOGS_CAROUSEL)

export const isBlogListing = item =>
  item[":type"]?.includes(COMPONENT_TYPES.BLOGS_LISTING)

export const isInspirationLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSPIRATION_LANDING)
}
export const isInspirationDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSPIRATION_DETAIL)
}
export const isPressLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRESS_LANDING)
}
export const isManualPDP = item =>
  item[":type"]?.includes(COMPONENT_TYPES.MANUALPDP)
export const isManualPDP_TABS = item =>
  item[":type"]?.includes(COMPONENT_TYPES.MANUALPDP_TABS)

export const isFindAStoreLandingV2 = item =>
  item[":type"]?.includes("findstorelandingv2")

export const isBrowseAllStores = item =>
  item[":type"]?.includes(COMPONENT_TYPES.BROWSE_ALL_STORES)

export const isStoreFilter = item =>
  item[":type"]?.includes(COMPONENT_TYPES.STORE_FILTER)
export const isTradeMembership = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.Trade_Membership)
}

export const isAsProductList = item =>
  item[":type"]?.includes(COMPONENT_TYPES.AS_PRODUCT_LISTING)

export const isProductDetails = item =>
  item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS)
export const isProductTabDetails = item =>
  item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_TAB_DETAIL)
export const isTitle = item =>
  item[":type"]?.includes(COMPONENT_TYPES.TITLE)
export const isButton = item =>
  item[":type"]?.includes(COMPONENT_TYPES.BUTTON)
export const isProductCard = item =>
  item[":type"]?.includes(COMPONENT_TYPES.PRODUCTCARD)

export * from "../src/utils"
